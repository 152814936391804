import store from "@/store";

class IdHelper {
  /**
   * Returns a luEduPersonPrimaryId dependent on the environment and if a querystring parameter has been retrieved
   * Querystring parameter can be used in the URL to imitate note takers
   * ?lucatid=fr5643li
   */
  getLuEduPersonPrimaryId(lucatId: string): string {
    const currentUser = store.state.currentUser;
    const currentUserId = currentUser ? currentUser.luEduPersonPrimaryId : "";

    const luEduPersonPrimaryId = currentUser?.entitlements?.includes(
      "SuperAdmin"
    )
      ? lucatId ?? currentUserId
      : currentUserId;

    return luEduPersonPrimaryId;
  }

  /**
   * Returns a personalId dependent on the environment and if a querystring parameter has been retrieved
   * Querystring parameter can be used in the URL to imitate another user
   * ?personalId=123456789123
   */
  getPersonalId(personalId: string): string {
    const currentUser = store.state.currentUser;
    const currentUserId = currentUser ? currentUser.personalId : "";
    const env = process.env.VUE_APP_ENV_NAME;

    if (!personalId) {
      return currentUserId;
    }

    const result =
      env === "Development" ||
      env === "Test" ||
      currentUser?.entitlements?.includes("SuperAdmin") ||
      currentUser?.entitlements?.includes("Tuition fees administratör")
        ? personalId ?? currentUserId
        : currentUserId;

    return result;
  }
}

export default new IdHelper();
