import dayjs from "dayjs";
import { Constants } from "@/constants/constants";

type DateType = dayjs.Dayjs | Date | string;

class DateHelper {
  /*
   * Compared the two dates
   * Returns 0 if the dates are equal
   * Returns -1 if dateA is before dateB
   * Returns 1 if dateA is after dateB
   */
  dateCompareFunction(dateA: DateType, dateB: DateType): -1 | 0 | 1 {
    const a = dayjs(dateA);
    const b = dayjs(dateB);
    if (a.isBefore(b)) return -1;
    if (a.isAfter(b)) return 1;
    return 0;
  }

  /*
   * Formats the date to the specific format
   * Defaults to YYYY-MM-DD
   */
  formatDate(date: DateType, format: string = Constants.DateFormat): string {
    return date ? dayjs(date).format(format) : "";
  }

  /*
   * Returns a boolean value indicating whether dateA is before dateB
   */
  dateAisBeforeDateB(
    dateA: string | DateType,
    dateB: string | DateType
  ): boolean {
    return dayjs(dateA).isBefore(dayjs(dateB));
  }

  /*
   * Returns the number of days between the 2 specified dates
   */
  daysBetweenDates(
    earlierDate: string | DateType,
    laterDate: string | DateType
  ): number {
    return dayjs(laterDate).diff(dayjs(earlierDate), "days");
  }

  /*
   * Adds the number of specified days to the specified date
   */
  addDaysToDate(date: DateType, days: number): Date {
    const result = dayjs(date).add(days, "day");
    return dayjs(result).toDate();
  }
}

export default new DateHelper();
