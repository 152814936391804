import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["name"]
const _hoisted_2 = { class: "custom-control custom-radio mr-3" }
const _hoisted_3 = ["id", "name", "value", "checked", "disabled", "required", "onChange"]
const _hoisted_4 = ["for"]
const _hoisted_5 = {
  key: 0,
  class: "form-text text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("fieldset", {
      name: `${_ctx.name}_RadioGroup`,
      class: _normalizeClass(["d-flex", [
      this.verticalLayout ? 'flex-column' : 'flex-row',
      this.showFieldset ? '' : 'border-0',
      !this.showTitle && this.showFieldset ? 'pt-3' : '',
    ]])
    }, [
      _withDirectives(_createElementVNode("legend", null, _toDisplayString(_ctx.name), 513), [
        [_vShow, _ctx.showTitle]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value) => {
        return (_openBlock(), _createElementBlock("div", { key: value }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("input", {
              type: "radio",
              class: "custom-control-input",
              id: `${_ctx.name}_RadioGroup_${value}`,
              name: `${_ctx.name}_RadioGroup`,
              value: value,
              checked: value === _ctx.selected,
              disabled: _ctx.disabled,
              required: _ctx.required,
              onChange: ($event: any) => (_ctx.onChange(value)),
              ref_for: true,
              ref: "input",
              onInvalid: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.validityMessage ? _ctx.setValidityMessage(_ctx.validityMessage) : null
          ))
            }, null, 40, _hoisted_3),
            _createElementVNode("label", {
              class: "custom-control-label",
              for: `${_ctx.name}_RadioGroup_${value}`
            }, [
              _renderSlot(_ctx.$slots, "default", { value: value }, () => [
                _createTextVNode(_toDisplayString(value), 1)
              ])
            ], 8, _hoisted_4)
          ])
        ]))
      }), 128))
    ], 10, _hoisted_1),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.helpText), 1))
      : _createCommentVNode("", true)
  ], 64))
}